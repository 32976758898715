import IEntryListProps from "./interfaces/IEntryListProps"
import { ChangeEvent } from "react";
import { EntryCard } from "./EntryCard";

export const EntryList : React.FC<IEntryListProps> = ({entries, pinnedEntries, setActive, setSearchTerm, loadMoreEntries} : IEntryListProps) : JSX.Element => {
    function handleSearchChange(event: ChangeEvent<HTMLInputElement>): void {
        setSearchTerm(event.target.value);
    }

    return (
        <div className="entry-list">
            Pinned Entries:
            {pinnedEntries.map(entry => <EntryCard key={entry.id} setActive={setActive} entry={entry} />)}
            <br/><br/><br/>
            <form>
                <label>Archive:</label>
                <input className="stretch" onChange={handleSearchChange} name="title" type="input"></input>
            </form>
            {entries.map(entry => 
                <EntryCard key={entry.id} setActive={setActive} entry={entry} />
            )}
            <br/>
            <div onClick={loadMoreEntries}>Load more</div>
        </div>
    )
}
