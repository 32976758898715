import React, { useEffect, useState } from "react";
import IJournalProps from "./interfaces/IJournalProps";
import { postNewEntry, getEntries, updateEntry, deleteEntry, getPinnedEntries } from "../services/Api";
import { EntryList } from "./EntryList";
import { EntryEditor } from "./EntryEditor";
import JournalEntry from "../models/JournalEntry";
import QueryParams from "../models/QueryParams";

export const Journal : React.FC<IJournalProps> = () : JSX.Element => {
    const [pinnedEntries, setPinnedEntries] = useState<Array<JournalEntry>>([new JournalEntry(null, "", "")]);
    const [entries, setEntries] = useState<Array<JournalEntry>>([new JournalEntry(null, "", "")]);
    const [activeEntry, setActiveEntry] = useState<JournalEntry>(new JournalEntry(null, "", ""));
    const [querySearchTerm, setQuerySearchTerm] = useState<string>("");
    const [queryTake, setQueryTake] = useState<number>(20);

    const handleSubmit = async (entry: JournalEntry) => {
        const submitEntry = async (entry : JournalEntry) =>{
            let inserted : JournalEntry | null;
        
            if(entry.id == null){
                inserted = await postNewEntry(entry);
            }
            else{
                inserted = await updateEntry(entry);
            }

            return inserted;
        }
        
        const rebuildEntryList = async (inserted: JournalEntry) => {
            if(inserted != null){
                const newEntry = new JournalEntry(inserted.id, inserted.title, inserted.content, inserted.mood, inserted.isPinned, new Date(inserted.createdDate), new Date(inserted.modifiedDate))
                
                let newEntries : Array<JournalEntry> = [...entries.filter(f => f.id !== null)];
                const existingIndex = entries.findIndex(e => e.id === newEntry?.id);
    
                if(existingIndex !== -1){
                    newEntries.splice(existingIndex, 1);
                }
    
                newEntries = [newEntry, ...newEntries];

                return newEntries;
            }
        }
        
        submitEntry(entry)
            .then(inserted => {
                setActiveEntry(inserted!);
                console.log()
                return inserted;
            })
            .then(inserted  => rebuildEntryList(inserted!))
            .then(newEntries => setEntries(newEntries!))
            .catch(err => console.log(err));
    }

    const handleDelete = async (id: number) => {
        const res = await deleteEntry(id);

        if(res === true){
            const index = entries.findIndex(e => e.id === id);
            const newEntries = [...entries];
            newEntries.splice(index,1);
            setEntries(newEntries);
            setActiveEntry(newEntries[0]);
        }
    }

    function handleLoadMoreEntries(): void {
        const newTake = queryTake + 20;
        setQueryTake(newTake);
    }

    useEffect(() => {
        const fetchEntries = async () => {
            const res = await getEntries(new QueryParams(querySearchTerm, 0, queryTake));
            let entries = new Array<JournalEntry>();

            res.forEach(r => {
                entries.push(new JournalEntry(r.id, r.title, r.content, r.mood, r.isPinned, new Date(r.createdDate), new Date(r.modifiedDate)))
            })

            return entries;
        }

        fetchEntries().then(res => setEntries(res)).catch(err => console.log(err));
    }, [querySearchTerm, queryTake])

    useEffect(() => {
        const fetchPinnedEntries = async () => {
            const res = await getPinnedEntries();
            let pinnedEntries = new Array<JournalEntry>();

            res.forEach(r => {
                pinnedEntries.push(new JournalEntry(r.id, r.title, r.content, r.mood, r.isPinned, new Date(r.createdDate), new Date(r.modifiedDate)))
            })

            return pinnedEntries;
        }

        fetchPinnedEntries().then(res => setPinnedEntries(res)).catch(err => console.log(err));
    }, [])

    return (
        <div className="page-container">
            <EntryList entries={entries} pinnedEntries={pinnedEntries} setActive={setActiveEntry} setSearchTerm={setQuerySearchTerm} loadMoreEntries={handleLoadMoreEntries}/>
            <EntryEditor entry={activeEntry} onSubmit={handleSubmit} onDelete={handleDelete} setActive={setActiveEntry}/>
            
        </div>
    )
}
