import { ChangeEvent, useEffect, useState } from "react";
import JournalEntry from "../models/JournalEntry"
import IEntryEditorProps from "./interfaces/IEntryEditorProps"
import { postNewEntry, updateEntry } from '../services/Api'
import { IconLock, IconLockOpen, IconDatabasePlus, IconDatabaseCog, IconDatabaseMinus, IconPin, IconPinFilled } from "@tabler/icons-react";

export const EntryEditor : React.FC<IEntryEditorProps> = ({entry: journalEntry, onSubmit, onDelete, setActive} : IEntryEditorProps) : JSX.Element => {
    const [entry, setEntry] = useState(journalEntry); 
    const [title, setTitle] = useState(journalEntry.title);
    const [mood, setMood] = useState(journalEntry.mood);
    const [content, setContent] = useState(journalEntry.content);
    const [locked, setLocked] = useState(false);
    const [pinned, setPinned] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(0);

    const handleSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const now = new Date()
        const newEntry = new JournalEntry(entry.id, title, content, mood, pinned, now, now);
        onSubmit(newEntry);        
    }

    const handleDelete = (e: React.MouseEvent) => {

        if(locked || entry.id === null)
            return;

        onDelete(entry.id);
    }

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setTitle(event.target.value);
    }

    const handleContentChange = async (event: ChangeEvent<HTMLTextAreaElement>): Promise<void> => {
        setContent(event.target.value);

        let timeStamp = new Date().getTime();
        
        if(Math.abs(timeStamp - lastUpdated) < 5000){
            return;
        }

        const now = new Date()
        const newEntry = new JournalEntry(entry.id, title, content, mood, pinned, now, now);

        setLastUpdated(timeStamp);

        if(entry.id === undefined || entry.id === null){
            const created = await postNewEntry(newEntry);
            if(created != null)
                setEntry(created);   
        }
        else{
            updateEntry(newEntry);
        }

    }

    const handleLockChange = (lock: boolean): void => {
        setLocked(lock);
    }

    const handlePinnedChange = (isPinned: boolean): void => {
        if(locked)
            return;

        setPinned(isPinned);
    }

    const handleMoodChange = (event : ChangeEvent<HTMLSelectElement>) : void => {
        setMood(parseInt(event.target.value));
    }

    const handleCreateNewEntry = (event : React.MouseEvent<HTMLElement>) : void => {
        event.preventDefault();
        setActive(new JournalEntry(null, "", ""));
    }

    useEffect(() => {
        setTitle(journalEntry.title);
        setContent(journalEntry.content);
        setMood(journalEntry.mood);
        setEntry(journalEntry);
        
        if(journalEntry.id == null){
            setLocked(false);
        }
        else{
            setLocked(true);
        }

    }, [journalEntry]);

    return (
        <div className="entry-editor">
            <form className="entry-editor-form" >
                <div>
                    <label>Title</label><br/>
                    <input className="stretch" type='text' onChange={handleTitleChange} name="title" disabled={locked} value={title}></input>
                </div>
                <div>
                    <label>Content</label><br/>
                    <textarea className="stretch content-textarea" onChange={handleContentChange} name="content" rows={20} disabled={locked} value={content} />
                </div>
                <div>
                    <button className="btn-success" onClick={handleCreateNewEntry}><IconDatabasePlus className="mb-1"/>New Entry</button>
                    <IconDatabaseMinus  onClick={handleDelete} className="d-inline-block align-middle mt-2 ms-2 float-middle" />
                    {locked === true ? <IconLock className="d-inline-block align-middle mt-2 ms-2 float-middle" onClick={() => handleLockChange(false)} /> : <IconLockOpen className="d-inline-block align-middle mt-2 ms-2 float-middle" onClick={() => handleLockChange(true)}/>}
                    {entry.isPinned === true ? <IconPinFilled className="d-inline-block align-middle mt-2 ms-2 float-middle" onClick={() => handlePinnedChange(false)} /> : <IconPin className="d-inline-block align-middle mt-2 ms-2 float-middle" onClick={() => handlePinnedChange(true)}/>}
                    
                    <button className="btn col-12" type="submit" onClick={handleSubmit} disabled={locked}><IconDatabaseCog className="mb-1"/> Save</button>
                    <select name="mood" id="mood-select" value={mood} disabled={locked} onChange={handleMoodChange}>
                        <option value="0" className="mood-0">Unknown</option>
                        <option value="1" className="mood-1">Red</option>
                        <option value="2" className="mood-2">Yellow</option>
                        <option value="3" className="mood-3">Green</option>
                    </select>
                </div>
               
            </form>
        </div>
    )
}
